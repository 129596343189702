import {
    CloudDownloadOutlined,
    CloudUploadOutlined,
    DeliveredProcedureOutlined,
    PrinterOutlined
} from '@ant-design/icons';
import { PlainObject } from '@gilbarbara/types';
import { Flex, Space, Typography } from 'antd';
import { toJS } from 'mobx';
import { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { Action, MetaField } from 'modules/services/backend-api/generated_info';
import { ButtonWithTooltips } from 'ui';
import { IObjectWithId, metaStore } from 'utils/store/MetaStore';
import { Response } from 'modules/services/backend-api/generated_api';
import { exportDataToExcel } from 'smart/utils/exportTable';
import { makeErrorReadable } from 'utils/helpers/makeErrorReadable';
import { useNotifications } from 'utils/hooks';
import { useReactToPrint } from 'react-to-print';
import { getMockActionDataByGetPartially } from '../../utils';
import { ExcelField } from '../../../../components';

interface SmartTablePageToolbarProps {
    meta: string;
    selectedRowKeys: string[];
    selectedRows: IObjectWithId[];
    hasSelected: boolean;
    totalDataCount: number;
    fieldsSource: MetaField[];
    onClickAction: (
        action: Action,
        hardIds?: string[],
        hardArgs?: PlainObject
    ) => Promise<Response | undefined>;
}

type PrintDataArray = { value: string }[][];

export const SmartTablePageFooterToolbar = memo<SmartTablePageToolbarProps>(
    ({
        meta,
        selectedRowKeys,
        hasSelected,
        totalDataCount,
        selectedRows,
        fieldsSource,
        onClickAction
    }) => {
        const { t } = useTranslation();

        const actions = toJS(metaStore.meta.get(meta)?.info?.Actions);

        const { notification } = useNotifications();

        const toPrintRef = useRef<HTMLDivElement | null>(null);
        const handlePrint = useReactToPrint({
            content: () => toPrintRef.current
        });

        // const [isImportModalOpen, setImportModalOpen] = useState(false);
        // const [showPrinttingFormsModal, setPrinttingFormsModal] = useState(false);
        const [printData, setPrintData] = useState<PrintDataArray>([]);

        // TODO: корректное преобразование всех значений к строке
        useEffect(() => {
            const lastSelectedItem = selectedRows.at(-1);
            if (lastSelectedItem) {
                setPrintData(
                    Object.entries(lastSelectedItem).map(([key, value]) => [
                        { value: key },
                        { value: value?.toString() }
                    ])
                );
            }
        }, [selectedRows.at(-1)]);

        const handleExportToExcel = async () => {
            const error: null | Error = null;

            if (error) {
                notification.error({
                    message: t('error'),
                    description: makeErrorReadable((error as Error).message)
                });
            }

            exportDataToExcel({
                metaName: meta,
                tableData: selectedRows,
                columns: fieldsSource
            });
        };

        return (
            <>
                <Flex justify="space-between" className="smart_table__footer">
                    <div>
                        {/* ### импорт/экспорт */}
                        <Space.Compact className="smart_table__footer_left">
                            <ButtonWithTooltips
                                type="default"
                                className=""
                                id="massPrintBtn"
                                disabled={!hasSelected}
                                tooltipTitle={t('massPrintBtn')}
                                tooltipPlacement="top"
                                onClick={handlePrint}
                                icon={<PrinterOutlined />}
                            />
                            <ButtonWithTooltips
                                type="default"
                                className=""
                                id="download_outlined"
                                tooltipTitle={t('download_outlined')}
                                tooltipPlacement="top"
                                icon={<CloudUploadOutlined />}
                                onClick={() => {
                                    // TODO: 19.08.2024 пока закомментили поиск экшна
                                    // if (actions) {
                                    //     const importAction = actions.find(
                                    //         // (action) => action.Handler_Code === 'ImportFromExcel'
                                    //         (action) => action.Handler?.MethodName === 'ImportFromExcel'
                                    //     );

                                    //     if (importAction) onClickAction(importAction, [v4()]);
                                    // }

                                    const action = getMockActionDataByGetPartially({
                                        Handler_Code: 'ImportFromExcel',
                                        Meta_Code: meta,
                                        Name: { ru: 'Импорт из Excel' },
                                        Handler: {
                                            ChildParams: [
                                                {
                                                    ParentHandler_Code: '',
                                                    ParamName: 'document_type_code',
                                                    Name: {
                                                        en: 'Document type',
                                                        ru: 'Тип документа'
                                                    },
                                                    ValueType: 'code;ref:DctDocumentTypes.Code',
                                                    IsRequested: false,
                                                    IsMandatory: false,
                                                    ChildIndex: 0
                                                },
                                                {
                                                    ParentHandler_Code: '',
                                                    ParamName: 'file_path',
                                                    Name: { en: 'File', ru: 'Файл' },
                                                    ValueType: 'file;bucket:downloads',
                                                    IsRequested: true,
                                                    IsMandatory: false,
                                                    ChildIndex: 1
                                                }
                                            ],
                                            Code: 'ImportFromExcel',
                                            Name: { ru: 'Импорт из Excel' },
                                            Meta_Code: meta
                                        }
                                    });

                                    onClickAction(action, [v4()]);
                                }}
                            />

                            <ButtonWithTooltips
                                type="default"
                                className=""
                                id="download_up_outlined"
                                tooltipTitle={t('download_up_outlined')}
                                tooltipPlacement="top"
                                icon={<CloudDownloadOutlined />}
                                onClick={() => {
                                    if (actions) {
                                        const exportAction = actions.find(
                                            // (action) => action.Handler_Code === 'ExportToExcel'
                                            (action) =>
                                                action.Handler?.MethodName === 'ExportToExcel'
                                        );

                                        if (exportAction) onClickAction(exportAction);
                                    }

                                    // const action = getMockActionDataByGetPartially({
                                    //     Handler_Code: 'ExportToExcel',
                                    //     Meta_Code: meta,
                                    //     Name: { ru: 'Экспорт в Excel' },
                                    //     Handler: {
                                    //         ChildParams: [],
                                    //         Code: 'ExportToExcel',
                                    //         Name: { ru: 'Экспорт в Excel' },
                                    //         Meta_Code: meta
                                    //     }
                                    // });

                                    handleExportToExcel();

                                    // onClickAction(action);
                                }}
                                disabled={selectedRowKeys.length === 0}
                            />

                            <ButtonWithTooltips
                                type="default"
                                className=""
                                id="mass_update"
                                tooltipTitle={t('mass_update')}
                                tooltipPlacement="top"
                                icon={<DeliveredProcedureOutlined />}
                                onClick={() => {
                                    if (actions) {
                                        const massUpdateAction = actions.find(
                                            // (action) => action.Handler_Code === 'ExportToExcel'
                                            (action) => action.Handler?.MethodName === 'MassUpdate'
                                        );

                                        if (massUpdateAction) onClickAction(massUpdateAction);
                                    }

                                    const action = getMockActionDataByGetPartially({
                                        Handler_Code: 'MassUpdate',
                                        Meta_Code: meta,
                                        Name: { ru: 'Массовое редактирование', en: 'Mass update' },
                                        Handler: {
                                            ChildParams: [],
                                            Code: 'MassUpdate',
                                            Name: {
                                                ru: 'Массовое редактирование',
                                                en: 'Mass update'
                                            },
                                            Meta_Code: meta
                                        }
                                    });

                                    onClickAction(action);

                                    // const action = getMockActionDataByGetPartially({
                                    //     Handler_Code: 'ExportToExcel',
                                    //     Meta_Code: meta,
                                    //     Name: { ru: 'Экспорт в Excel' },
                                    //     Handler: {
                                    //         ChildParams: [],
                                    //         Code: 'ExportToExcel',
                                    //         Name: { ru: 'Экспорт в Excel' },
                                    //         Meta_Code: meta
                                    //     }
                                    // });

                                    // handleExportToExcel();

                                    // onClickAction(action);
                                }}
                                disabled={selectedRowKeys.length === 0}
                            />

                            {/* <ButtonWithTooltips
                                type="default"
                                className=""
                                id="massPrintBtn"
                                disabled={!hasSelected}
                                tooltipTitle={t('massPrintBtn')}
                                tooltipPlacement="top"
                                onClick={() => {
                                    setPrinttingFormsModal(true);
                                }}
                                icon={<PrinterOutlined />}
                            /> */}
                        </Space.Compact>
                    </div>

                    <Flex className="smart_table__footer_center">
                        {/* TODO: center part of toolbar */}
                        <></>
                    </Flex>

                    <Flex className="smart_table__footer_right">
                        {/* TODO: right part of toolbar */}
                        <Typography.Text strong>
                            {selectedRowKeys.length
                                ? `${t('selected')} ${selectedRowKeys.length} ${t('out_of')} `
                                : null}
                            {totalDataCount} {t('rows')}
                        </Typography.Text>
                    </Flex>
                </Flex>

                <div style={{ display: 'none' }}>
                    <ExcelField file={printData} ref={toPrintRef} />
                </div>

                {/* <SelectPrintingFormModal */}
                {/*    open={showPrinttingFormsModal} */}
                {/*    setOpen={setPrinttingFormsModal} */}
                {/*    documentIds={selectedRowKeys.map((id) => Number(id))} */}
                {/*    tableName={toSnakeCase(meta) as TableName} */}
                {/*    viewName={`v${toSnakeCase(meta)}` as ViewName} */}
                {/* /> */}
            </>
        );
    }
);
