import { PlainObject } from '@gilbarbara/types';
import { Table } from 'antd';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { toJS } from 'mobx';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { EmptyMarker } from 'ui';

import {
    RegBookedLocationSlot,
    RegLocationResourceSlot
} from 'modules/services/backend-api/generated_models';
import { IObjectWithId, metaStore } from 'utils/store/MetaStore';
import { Loader } from 'ui/Loader/Loader';

import { Action, MetaField } from 'modules/services/backend-api/generated_info';
import { HandlerRunModal } from 'smart/components';
import { IHandlerWithId } from 'smart/modules/SmartDetailPage/components/SmartDetailPageHeaderToolbar/SmartDetailPageHeaderToolbar';
import { useHandlerRun } from 'smart/utils/hooks';
import { Response } from 'modules/services/backend-api/generated_api';
import { LANGUAGES } from 'utils/i18n/i18n';
import { FilterField } from 'smart/modules/SmartTablePage/components/SmartTableFilterMenu/types';
import { Filter } from 'modules/supabase/utils/supabaseClient';
import { fnv1aHash } from 'smart/utils';
import { useStoreNavigate } from 'utils/store';
// import { BookedSlotsDataType } from '../../types';
import './SmartTimeline.scss';
import { useAsync } from 'react-use';
import { observer } from 'mobx-react-lite';
import {
    getColumns,
    getColumnsRevert,
    getData,
    getDataRevert,
    getUniqItemsFromSlots,
    transformSlots
} from './utils';

interface SmartTimelineProps {
    daysCount: number;
    selectedDate: dayjs.Dayjs;
    // selectedLocations: any[];
    // bookedSlots: BookedSlotsDataType[];
    freeSlots: RegLocationResourceSlot[];
    bookedSlots: RegBookedLocationSlot[];
    selectedResources: IObjectWithId[];
    selectedLocations: IObjectWithId[];
    selectedTrips: IObjectWithId[];
    fetchBookedSlots: () => void;
    loading: boolean;
    firstColumnFieldName: string;
    displayMode: 'horizontal' | 'vertical';
    meta: string;
}

interface ResourceCoumnRenderDataType {
    [key: string]: /* resource_key */ {
        total: number;
        booked: number;
        free: number;
        resource_id: number | string;
    };
}

export const SmartTimeline = observer<SmartTimelineProps>(
    ({
        daysCount,
        selectedDate,
        freeSlots,
        bookedSlots,
        selectedResources,
        selectedLocations,
        selectedTrips,
        fetchBookedSlots,
        loading,
        firstColumnFieldName,
        displayMode,
        meta
    }) => {
        const {
            i18n: { language }
        } = useTranslation();
        const location = useLocation();
        const storeNavigate = useStoreNavigate();

        const { run } = useHandlerRun();

        const [handlerModal, setHandlerModal] = useState(false);
        const [handlerModalData, setHandlerModalData] = useState<Action | null>(null);
        const [handlerModalHardIds, setHandlerModalHardIds] = useState<string[] | undefined>();
        const [handlerModalRow, setHandlerModalRow] = useState<IObjectWithId | undefined>();

        const handleNavigateAfterAction = useCallback(
            (
                actionResponse: Response,
                action: Action,
                filters: FilterField[] | Filter[],
                metaFields?: MetaField[]
            ) => {
                // if (!uiAllowSelect) return;

                const pathnameLastElem = location.pathname.split('/').at(-1)?.trim();

                let pageTitle = `${location.state?.pageTitle || pathnameLastElem} (${t('filtered')})`;
                let pathname = location.pathname;
                // используем просутю hash функцию по методу fnv1 для создания уникально ключа кэша страницы
                let cacheKey = fnv1aHash(`${pathnameLastElem}_filtered_${JSON.stringify(filters)}`);

                if (action.NavItem && action.NavItem.Path) {
                    if (action.NavItem.Name) {
                        pageTitle = `${action.NavItem.Name[language] || action.NavItem.Name.en} (${t(
                            'filtered'
                        )})`;
                    }
                    // pathname = action.NavItem.Path;
                    pathname = action.NavItem.Path.includes('?')
                        ? action.NavItem.Path.split('?')[0]
                        : action.NavItem.Path; // TODO: нужно ли прилетающие тут параметры подмешивать в фильтры ??
                    // используем просутю hash функцию по методу fnv1
                    cacheKey = fnv1aHash(`${action.NavItem.Id}_${JSON.stringify(filters)}`);
                }

                // debugger;
                storeNavigate(
                    { pathname },
                    {
                        state: {
                            filters,
                            pageTitle,
                            cacheKey,
                            fromMeta: action.Meta_Code,
                            associationCode: action.Association_Code,
                            responseData: { ...actionResponse.run?.[0]?.Result, ids: [] },
                            metaFields
                        }
                    }
                );
            },
            [language, location.pathname, location.state?.pageTitle, storeNavigate]
        );

        const handleNavigateBeforeAction = useCallback(
            ({
                action,
                filters,
                metaFields,
                hardIds,
                hardArgs
            }: {
                action: Action;
                filters: FilterField[] | Filter[];
                metaFields?: MetaField[];
                hardIds?: string[];
                hardArgs?: PlainObject;
            }) => {
                // if (!uiAllowSelect) return;

                const pathnameLastElem = location.pathname.split('/').at(-1)?.trim();

                let pageTitle = `${location.state?.pageTitle || pathnameLastElem} (${t('filtered')})`;
                let pathname = location.pathname;
                // используем просутю hash функцию по методу fnv1 для создания уникально ключа кэша страницы
                let cacheKey = fnv1aHash(`${pathnameLastElem}_filtered_${JSON.stringify(filters)}`);

                if (action.NavItem && action.NavItem.Path) {
                    if (action.NavItem.Name) {
                        pageTitle = `${action.NavItem.Name[language] || action.NavItem.Name.en} (${t(
                            'filtered'
                        )})`;
                    }
                    // pathname = action.NavItem.Path;
                    pathname = action.NavItem.Path.includes('?')
                        ? action.NavItem.Path.split('?')[0]
                        : action.NavItem.Path; // TODO: нужно ли прилетающие тут параметры подмешивать в фильтры ??
                    // используем просутю hash функцию по методу fnv1
                    cacheKey = fnv1aHash(`${action.NavItem.Id}_${JSON.stringify(filters)}`);
                }

                storeNavigate(
                    { pathname },
                    {
                        state: {
                            filters,
                            pageTitle,
                            cacheKey,
                            fromMeta: action.Meta_Code,
                            associationCode: action.Association_Code,
                            requestData: {
                                action,
                                ids: hardIds ?? [],
                                args: hardArgs ?? {}
                            },
                            metaFields
                        }
                    }
                );
            },
            [language, location.pathname, location.state?.pageTitle, storeNavigate]
        );

        const actionClickHandler = useCallback(
            async (
                action: Action,
                hardIds?: string[],
                hardArgs?: PlainObject,
                row?: IObjectWithId
            ): Promise<Response | undefined> => {
                if (action.IsNavigationFirst) {
                    handleNavigateBeforeAction({ action, hardArgs, hardIds, filters: [] });
                } else if (action.Handler_Code) {
                    const isShowModal =
                        action.Handler &&
                        ((action.Handler.ChildParams?.length &&
                            !action.Handler?.ChildParams?.every((param) => !param.IsRequested)) ||
                            action.Handler_Code === 'MassUpdate');
                    if (isShowModal) {
                        setHandlerModal(true);
                        setHandlerModalData({
                            ...action,
                            Handler: {
                                ...action.Handler,
                                Id: action.Id!,
                                ChildParams: action.Handler?.ChildParams.map((p) => {
                                    if (hardArgs && Object.keys(hardArgs).includes(p.ParamName)) {
                                        return { ...p, DefaultValue: hardArgs[p.ParamName] };
                                    }

                                    return p;
                                })
                            } as IHandlerWithId
                        });
                        setHandlerModalHardIds(hardIds);
                        setHandlerModalRow(row);
                    } else {
                        const response = await run({
                            Action_Id: action.Id!,
                            meta: action.Meta_Code,
                            ids: hardIds ?? [],
                            handler: action.Handler_Code,
                            args: hardArgs || {}
                        });

                        return response;
                    }
                }

                return undefined;
            },
            // [refresh, run, selectedRowKeys, setLogMessages]
            [handleNavigateBeforeAction, run]
        );

        useAsync(async () => {
            const res = await Promise.all([metaStore.getInfo('DocTrips'), metaStore.getInfo(meta)]);

            console.log(res);
        }, [meta]);

        const tripActions = useMemo(() => {
            return toJS(metaStore.meta.get('DocTrips'))?.info?.Actions?.sort((action) =>
                action.Type_Code === 'FOLDER' ? -1 : 1
            );
        }, [metaStore.meta.get('DocTrips')?.info?.Actions]);

        const slotActions = useMemo(() => {
            return toJS(metaStore.meta.get(meta))?.info?.Actions;
        }, [metaStore.meta.get(meta)?.info?.Actions]);

        console.log(tripActions, slotActions);

        const getSlotActionsMenuItems = useCallback(
            (ids: string[], args: PlainObject, row: IObjectWithId) => {
                // DocDowntimeEvents.Create

                // HANDLER

                const action = slotActions?.find(
                    (a) => a.Handler_Code === 'DocDowntimeEvents.Create'
                );

                if (action) {
                    const item = {
                        key: action.Name[language as LANGUAGES] || action.Type_Code,
                        label: action.Name[language as LANGUAGES] || action.Id,
                        disabled: action.IsDisabled,
                        onClick: () => actionClickHandler(action, ids, args, row)
                    };

                    return [item];
                }

                return [];
            },
            [slotActions, actionClickHandler, language]
        );

        // const actionsMenuItems = useMemo(() => {
        const getTripActionsMenuItems = useCallback(
            (ids: string[], row: IObjectWithId) => {
                const folders: MenuItem[] = [];
                const folderless: MenuItem[] = [];

                for (const action of tripActions ?? []) {
                    if (
                        (!action.Handler_Code?.includes('RiseExecutionEvent') &&
                            !action.Handler_Code?.includes('ChangeStartTime') &&
                            !action.Handler_Code?.includes('ChangeServiceStartTime')) ||
                        !action.IsActive
                    )
                        continue;
                    // if (
                    //     !action.IsVisibleInList ||
                    //     (isShowActionButtons && action.IsButton) ||
                    //     !action.IsActive ||
                    //     (action.AllowedForNavItems?.length &&
                    //         !action.AllowedForNavItems.includes(currentNavItem?.id as string)) || // ID есть всегда - это я подразумеваю
                    //     ((action.Handler_Code === 'Delete' || action.Handler_Code === 'SoftDelete') &&
                    //         !uiAllowDelete)
                    // ) {
                    //     continue;
                    // }

                    if (action.IsFolder || action.Type_Code === 'FOLDER') {
                        folders.push({
                            key: action.Id!,
                            index: action.ChildIndex,
                            label: action.Name[language as LANGUAGES] || action.Id,
                            disabled: action.IsDisabled,
                            children: []
                        });
                    }

                    if (!action.IsFolder && action.Type_Code === 'HANDLER') {
                        const parentId = action.Parent_Id;

                        const folderIndex = folders.findIndex(
                            (folder) => folder && folder.key === parentId
                        );

                        const item = {
                            key: action.Name[language as LANGUAGES] || action.Type_Code,
                            label: action.Name[language as LANGUAGES] || action.Id,
                            disabled:
                                // (!action?.Handler?.IsForNoneObjects && !hasSelected) ||
                                action.IsDisabled,
                            // ||
                            // (action.IsSingle && selectedRowKeys.length > 1),
                            onClick: () => actionClickHandler(action, ids, undefined, row)
                        };

                        if (folderIndex !== -1) {
                            folders[folderIndex]?.children?.push(item);
                        } else {
                            folderless.push(item);
                        }
                    }

                    if (!action.IsFolder && action.Type_Code === 'EVENT') {
                        const parentId = action.Parent_Id;

                        const folderIndex = folders.findIndex(
                            (folder) => folder && folder.key === parentId
                        );

                        const item = {
                            key: action.Name[language as LANGUAGES] || action.Type_Code,
                            label: action.Name[language as LANGUAGES] || action.Id,
                            disabled:
                                // (!action?.Handler?.IsForNoneObjects && !hasSelected) ||
                                action.IsDisabled,
                            // ||
                            // (action.IsSingle && selectedRowKeys.length > 1),
                            onClick: () =>
                                actionClickHandler(
                                    {
                                        ...action,
                                        Handler_Code: 'RaiseEvent',
                                        Handler: { ...action.Handler, Code: 'RaiseEvent' }
                                    },
                                    // undefined,
                                    ids,
                                    {
                                        event_type: action.EventType_Code
                                    },
                                    row
                                )
                        };

                        if (folderIndex !== -1) {
                            folders[folderIndex]?.children?.push(item);
                        } else {
                            folderless.push(item);
                        }
                    }
                }

                return [
                    ...folders.sort((a, b) => Number(a?.index) - Number(b?.index)),
                    ...folderless
                ];
            },
            [
                tripActions,
                actionClickHandler,
                // isShowActionButtons,
                // currentNavItem?.id,
                // uiAllowDelete,
                language
                // hasSelected,
                // selectedRowKeys.length,
                // onClickAction
            ]
        );

        // console.log(handlerModalRow);

        return (
            <>
                <Table
                    // expandable={{
                    //     expandedRowRender: (row) => {
                    //         return <NestedSmartTable row={row} />;
                    //     }
                    // }}
                    rowKey={'Id'}
                    columns={
                        displayMode === 'horizontal'
                            ? getColumns({
                                  daysCount,
                                  selectedDate,
                                  freeSlots,
                                  bookedSlots: transformSlots(bookedSlots),
                                  fetchBookedSlots,
                                  getSlotActionsMenuItems,
                                  getTripActionsMenuItems,
                                  language,
                                  selectedTripsIds: selectedTrips?.map((st) => st.Id) ?? [],
                                  firstColumnFieldName,
                                  meta
                              })
                            : getColumnsRevert({
                                  items: getUniqItemsFromSlots({
                                      slots: freeSlots,
                                      bookedSlots,
                                      selectedTripIds: selectedTrips?.map((st) => st.Id) ?? [],
                                      firstColumnFieldName
                                  }),
                                  language,
                                  selectedTripsIds: selectedTrips?.map((st) => st.Id) ?? [],
                                  getSlotActionsMenuItems,
                                  getTripActionsMenuItems,
                                  fetchBookedSlots,
                                  bookedSlots: transformSlots(bookedSlots),
                                  freeSlots,
                                  firstColumnFieldName,
                                  meta
                              })
                    }
                    // dataSource={getData(selectedResources, language)}
                    dataSource={
                        displayMode === 'horizontal'
                            ? getData({
                                  freeSlots,
                                  bookedSlots: transformSlots(bookedSlots),
                                  selectedResources,
                                  language,
                                  daysCount,
                                  selectedTrips,
                                  selectedLocations,
                                  firstColumnFieldName
                                  // selectedDate
                              })
                            : getDataRevert({
                                  freeSlots,
                                  daysCount,
                                  selectedDate,
                                  bookedSlots: transformSlots(bookedSlots),
                                  firstColumnFieldName,
                                  selectedTrips
                              })
                    }
                    bordered
                    size="small"
                    pagination={false}
                    className="smart_timeline_table"
                    // scroll={{ y: 'calc(100vh - 27rem)' }}
                    scroll={{ y: 'calc(100vh - 21rem)' }}
                    locale={{ emptyText: <EmptyMarker /> }}
                    loading={
                        loading ? { spinning: loading, indicator: <Loader />, delay: 100 } : loading
                    }
                />
                {handlerModal && (
                    <HandlerRunModal
                        open={handlerModal}
                        onCancel={() => {
                            setHandlerModal(false);
                            setHandlerModalData(null);
                            setHandlerModalHardIds(undefined);
                            setHandlerModalRow(undefined);
                        }}
                        action={handlerModalData}
                        ids={handlerModalHardIds ?? []}
                        onRefresh={() => {}}
                        metaName={handlerModalData?.Meta_Code ?? 'DocTrips'}
                        navigateAfterRun={handleNavigateAfterAction}
                        // filters={filters}
                        meta={handlerModalData?.Meta_Code ?? 'DocTrips'}
                        row={handlerModalRow}
                    />
                )}
            </>
        );
    }
);
