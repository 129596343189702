import { forwardRef, useEffect, useState } from 'react';
import Spreadsheet from 'react-spreadsheet';
import * as XLSX from 'xlsx';
import { isArray } from 'is-lite/exports';

interface ExcelCellType {
    value: string | number | null | undefined;
}

interface ExcelFileProps {
    file: ArrayBuffer | Array<ExcelCellType[]>;
}

export const ExcelField = forwardRef<HTMLDivElement, ExcelFileProps>(({ file }, ref) => {
    const [data, setData] = useState<ExcelCellType[][]>([]);
    const type = isArray(file) ? 'array' : 'buffer';

    useEffect(() => {
        if (type === 'array') {
            console.log('sheet data', file);

            setData(file as Array<ExcelCellType[]>);
        } else {
            const workbook = XLSX.read(file, { type });
            const worksheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[worksheetName];
            const data = XLSX.utils.sheet_to_json(worksheet);

            const maxFields = Math.max(...data.map((obj) => Object.keys(obj).length));

            const transformedData = data.map((item) => {
                const entries = Object.entries(item);
                const transformedEntries = entries.map(
                    ([key, value]) => ({ value }) as ExcelCellType
                );

                // Заполняем недостающие позиции объектами с пустым значением
                while (transformedEntries.length < maxFields) {
                    transformedEntries.push({ value: '' });
                }

                return transformedEntries;
            });

            console.log('sheet data', transformedData);

            setData(transformedData);
        }
    }, [file, type]);

    return (
        <div ref={ref}>
            <Spreadsheet data={data} />
        </div>
    );
});
