import { MenuProps } from 'antd';
import { IObjectWithId } from '../../../../utils/store/MetaStore';

export enum Months {
    january,
    february,
    march,
    april,
    may,
    june,
    july,
    august,
    september,
    october,
    november,
    december
}

export enum Weeks {
    sunday,
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday
}

export type MenuItem = NonNullable<MenuProps['items']>[number] & {
    index?: number;
    children?: MenuItem[];
};

export interface ItemDataType {
    Id: string;

    Date: string;
    Hour: number;
    Time: string;
    DateTime: string;
    DaysCount: number;
    [id: string]: any;
}

export interface DayDataType {
    Key?: string; // & React.Key;
    Name?: string;
    Id: string;
    ItemId: string;
    TripId?: string;
    TripIndex?: string;
    Trip?: IObjectWithId;
    // FromAt: Date | string;
    // ToAt: Date | string;
    // FreeHoursPerDay: number[];
    // FreeSlotsCount: number;
    // BookedSlotsCount: number;
    CapacityCount: number;
    TripsIds: string[];
    Trips: IObjectWithId[];
    Meta: string;
    children?: Omit<DayDataType, 'Trips' | 'TripsIds'>[];
    // [hour: number]: RegBookedLocationSlot | undefined;
    [hour: number]: number;
    // [hour: number]: string;
}
