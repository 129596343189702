import { isEmpty } from 'is-lite/exports';
import { DayDataType } from './types';
import {
    RegBookedLocationSlot,
    RegLocationResourceSlot
} from '../../../../modules/services/backend-api/generated_models';
import { IObjectWithId } from '../../../../utils/store/MetaStore';
import { HOURS } from '../constants';

type GetDataFunction = (options: {
    daysCount: number;
    freeSlots: RegLocationResourceSlot[];
    bookedSlots: RegBookedLocationSlot[];
    selectedResources: IObjectWithId[];
    selectedLocations: IObjectWithId[];
    selectedTrips: IObjectWithId[];
    language: string;
    firstColumnFieldName: string;
    // selectedDate: dayjs.Dayjs;
    // fetchBookedSlots: () => void;
}) => DayDataType[];

export const getData: GetDataFunction = ({
    freeSlots,
    language,
    bookedSlots,
    selectedTrips,
    firstColumnFieldName
}) => {
    const data: DayDataType[] = [];
    const selectedTripIds = selectedTrips.map((t) => t.Id);

    for (const slot of freeSlots) {
        if (!slot[firstColumnFieldName]) continue;

        if (data.find((d) => d.Id === slot[firstColumnFieldName]?.Id)) continue; // не будем дублировать ресурсы

        if (
            !isEmpty(selectedTrips) &&
            !bookedSlots.find(
                (bs) =>
                    bs[firstColumnFieldName]?.Id === slot[firstColumnFieldName]?.Id &&
                    selectedTripIds.includes(bs?.Trip?.Id ?? '')
            )
        )
            continue;

        const trips = Array.from(
            new Map(
                (
                    bookedSlots
                        .filter(
                            (bs) =>
                                bs[firstColumnFieldName]?.Id === slot[firstColumnFieldName]?.Id &&
                                bs.Trip
                        )
                        .sort((a, b) => {
                            if (a.Date && b.Date) {
                                const dateComparison =
                                    new Date(a.Date).getTime() - new Date(b.Date).getTime();
                                if (dateComparison !== 0) {
                                    return dateComparison; // Если даты разные, возвращаем разницу
                                }
                            }
                            // Если даты одинаковые, сравниваем по SlotIndex
                            return (a.SlotIndex ?? 0) - (b.SlotIndex ?? 0);
                        })
                        .map((bs) => bs.Trip as IObjectWithId) ?? []
                ).map((obj) => [`${obj.Id}`, obj])
            ).values()
        );
        const CapacityCount = slot[firstColumnFieldName]?.CapacityCount ?? 0;
        const Id = slot[firstColumnFieldName]?.Id ?? '';
        const Name =
            slot[firstColumnFieldName]?.Name?.[language] ??
            slot[firstColumnFieldName]?.Key ??
            slot[firstColumnFieldName]?.Id;

        const dataItem: DayDataType = {
            Key: slot[firstColumnFieldName]?.Key,
            Name,
            Id,
            ItemId: Id,
            TripId: undefined,
            CapacityCount,
            TripsIds: trips.map((t) => t?.Id),
            Trips: trips,
            Meta: 'CatLocationResources', // TODO: либо убрать, либо прокинуть пропс meta
            ...HOURS,

            children: trips.length
                ? trips.map((t, index) => {
                      return {
                          Key: t.Key,
                          Name: t?.Name?.[language] ?? t?.Key ?? t?.Id,
                          Id: t?.Id ?? '',
                          Trip: t,
                          TripId: t?.Id ?? '',
                          TripIndex: index,
                          ItemId: Id,
                          Meta: 'DocTrips',
                          CapacityCount,
                          ...HOURS
                      };
                  })
                : undefined
        };

        data.push(dataItem);
    }

    // console.log(freeSlots, data, bookedSlots);

    return data; // TODO: sort
    // return data.sort((a, b) => (a.key > b.key ? 1 : -1));
};
