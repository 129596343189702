import { t } from 'i18next';
import {
    Badge,
    Button,
    Divider,
    Dropdown,
    Flex,
    Popover,
    Space,
    TableColumnsType,
    Tooltip,
    Typography
} from 'antd';
import React, { CSSProperties } from 'react';
import { MoreOutlined } from '@ant-design/icons';
import { isDefined } from 'is-lite/exports';
import { PlainObject } from '@gilbarbara/types';
import dayjs from 'dayjs';
import { ItemDataType, MenuItem } from './types';
import { ColorMarker } from '../ui';
import { getGetFreeHoursByDate } from './getFreeHoursByDate';
import { DATE_TIME_FORMAT } from '../../../../utils/helpers/dates';
import { IObjectWithId, metaStore } from '../../../../utils/store/MetaStore';
import {
    RegBookedLocationSlot,
    RegLocationResourceSlot
} from '../../../../modules/services/backend-api/generated_models';
import { StoreLink } from '../../../../ui';

type GetColumnsRevertFunction = (options: {
    items: IObjectWithId[];
    language: string;
    selectedTripsIds: string[];
    getTripActionsMenuItems: (ids: string[], row: IObjectWithId) => MenuItem[];
    getSlotActionsMenuItems: (ids: string[], args: PlainObject, row: IObjectWithId) => MenuItem[];
    fetchBookedSlots: () => void;
    bookedSlots: RegBookedLocationSlot[];
    freeSlots: RegLocationResourceSlot[];
    firstColumnFieldName: string;
    meta: string;
}) => TableColumnsType<ItemDataType>;

export const getColumnsRevert: GetColumnsRevertFunction = ({
    items,
    language,
    selectedTripsIds,
    getTripActionsMenuItems,
    getSlotActionsMenuItems,
    fetchBookedSlots,
    bookedSlots,
    freeSlots,
    firstColumnFieldName,
    meta
}) => {
    const tripsMeta = 'DocTrips';

    const metaRoutes = metaStore.meta.get('all')?.routes;
    const itemsRoute = metaRoutes?.find((route) => route.meta === meta);
    const tripsRoute = metaRoutes?.find((route) => route.meta === tripsMeta);

    const itemsMetaInfo = metaStore.meta.get(meta)?.info;
    const itemsUiAllowView = itemsMetaInfo?.UiAllowView ?? true;

    const tripsMetaInfo = metaStore.meta.get(tripsMeta)?.info;
    const tripsUiAllowView = tripsMetaInfo?.UiAllowView ?? true;

    return [
        {
            title: t('datetime'),
            // dataIndex: 'resource',
            dataIndex: 'DateTime',
            // key: 'resource',
            key: 'DateTime',
            fixed: 'left',
            width: 250,
            // responsive,
            ellipsis: true
            // render: (dateTime, { Date, Hour, Time, DaysCount }) => {
            //     return dateTime;
            //     // return (
            //     //     <Flex gap={5}>
            //     //         <span>{Date}</span>
            //     //         <span>{Time}</span>
            //     //     </Flex>
            //     // );
            // }
        },
        ...items.map((item) => {
            return {
                title: item.Name?.[language],
                dataIndex: item.Id,
                key: item.Id,
                width: 200,
                ellipsis: true,
                render: (id, { Date, Hour, Time, DaysCount }) => {
                    const border: CSSProperties['border'] = '2px solid red';

                    let showSelectedTripBorder = false;

                    let totalWeightValue = 0;

                    const bookedSlotsData = bookedSlots.filter(
                        (slot) =>
                            slot[firstColumnFieldName]?.Id === id &&
                            String(slot.SlotDate) === Date &&
                            slot.SlotIndex === Hour
                        // &&
                        // (!TripId || slot.Trip?.Id === TripId) // если строка размернута и относится к рейсу
                    );

                    const slotData = freeSlots.find(
                        (s) => s.SlotDate === Date && s[firstColumnFieldName]?.Id === id
                    );

                    const CapacityCount = slotData?.CapacityCount ?? 0;
                    const BookedCapacity = bookedSlotsData.reduce((acc, bs) => {
                        if (isDefined(bs.CapacityCount)) return acc + (bs.CapacityCount ?? 0);

                        return 1;
                    }, 0);
                    let Status = getGetFreeHoursByDate()(freeSlots, id, firstColumnFieldName)
                        .get(Date)
                        ?.includes(Hour)
                        ? 'free'
                        : 'closed';
                    if (BookedCapacity > 0) Status = 'filled';
                    const CellColor = bookedSlotsData?.[0]?.Status?.Color;
                    const SlotFillPercent = Math.max(...bookedSlotsData.map((s) => s.SlotPercent));
                    const IsSlotStart = bookedSlotsData?.[0]?.IsSlotStart;

                    const tripLinks = bookedSlotsData.map((bs) => {
                        const tripId = bs.Trip?.Id;
                        showSelectedTripBorder = selectedTripsIds.includes(tripId ?? ''); // выделям рамкой если рейс выбран в отборе ИЛИ если это развернутая строка с рейсом
                        const tripLabel = bs.Trip?.Key;
                        const color = bs.Status?.Color ?? 'lightgray';
                        const statusName = bs.Status?.Name?.[language];

                        totalWeightValue += bs.Trip?.TotalWeightValue ?? 0;

                        return (
                            <Space style={{ padding: '5px 11px' }}>
                                <Tooltip title={statusName}>
                                    <Badge color={color} />
                                </Tooltip>
                                {tripsRoute && tripsUiAllowView ? (
                                    <StoreLink
                                        key={tripId}
                                        style={{ padding: 0 }}
                                        to={`${tripsRoute.path}/${tripId}`}
                                        state={{ data: bs?.Trip }}
                                    >
                                        {tripLabel}
                                    </StoreLink>
                                ) : (
                                    tripLabel
                                )}
                                <Dropdown
                                    menu={{ items: getTripActionsMenuItems([tripId], bs.Trip) }}
                                    trigger={['click']}
                                    placement="bottomLeft"
                                    destroyPopupOnHide
                                >
                                    <Button type="text">
                                        <MoreOutlined />
                                    </Button>
                                </Dropdown>
                            </Space>
                        );
                    });

                    const capacityDiff = CapacityCount - BookedCapacity;

                    const slotInfo = `${t('slot')} ${Hour + 1} (${Hour}:00-${Hour + 1}:00)`;
                    const bottomText = `${t('busy')} ${BookedCapacity}/${CapacityCount}`;
                    const hasText = bookedSlotsData.length > 1;
                    const slotStartTime = dayjs(Date)
                        .set('hours', Hour)
                        .set('minutes', 0)
                        .set('seconds', 0)
                        .set('milliseconds', 0)
                        .format(DATE_TIME_FORMAT);
                    const slotEndTime = dayjs(Date)
                        .set('hours', Hour + 1)
                        .set('minutes', 0)
                        .set('seconds', 0)
                        .set('milliseconds', 0)
                        .format(DATE_TIME_FORMAT);

                    // const blockHandler = async (capacity_count: 1 | -1) => {
                    //     await metaStore.makeRun({
                    //         Action_Id: '',
                    //         meta: 'CatLocationResources',
                    //         ids: [id],
                    //         handler: 'CatLocationResources.BookSlot',
                    //         args: {
                    //             planning_date: Date,
                    //             slot_index: Hour,
                    //             capacity_count
                    //         }
                    //     });
                    //
                    //     fetchBookedSlots();
                    // };
                    //
                    // const menuItems: MenuProps['items'] = [
                    //     {
                    //         key: 0,
                    //         label: t('block_slot'),
                    //         onClick: () => blockHandler(1),
                    //         disabled: Status === 'closed'
                    //     },
                    //     {
                    //         key: 1,
                    //         label: t('unblock_slot'),
                    //         onClick: () => blockHandler(-1),
                    //         disabled: Status === 'closed' || Status === 'free'
                    //     }
                    // ];

                    const driverInfos = [];

                    for (const bs of bookedSlotsData) {
                        const driver = bs.Trip?.Driver?.Name?.[language];
                        const vehicle = bs.Trip?.FirstVehicle?.Name?.[language];

                        const info = `${driver ?? ''}${vehicle ? ` / ${vehicle}` : ''}`;

                        if (info) driverInfos.push(info);
                    }

                    const hasDriverInfos = !!driverInfos?.length;

                    return (
                        <Dropdown
                            dropdownRender={(menu) => {
                                return (
                                    <div className={'smart_timeline_table__slot_context'}>
                                        <Typography.Text strong>{slotInfo}</Typography.Text>
                                        <Divider style={{ margin: '5px 0' }} />
                                        {/* {tripLink} */}
                                        {Status === 'filled' || Status === 'partial_filled' ? (
                                            <>
                                                {tripLinks}
                                                <Divider style={{ margin: '5px 0' }} />
                                            </>
                                        ) : null}
                                        {React.cloneElement(menu as React.ReactElement, {
                                            style: {
                                                backgroundColor: 'transparent',
                                                boxShadow: 'none',
                                                padding: 0
                                            }
                                        })}
                                        {Status !== 'closed' ? (
                                            <>
                                                <Divider style={{ margin: '5px 0' }} />
                                                <Typography.Text>{bottomText}</Typography.Text>
                                            </>
                                        ) : null}
                                    </div>
                                );
                            }}
                            menu={{
                                items: getSlotActionsMenuItems(
                                    [id],
                                    {
                                        start_time: slotStartTime,
                                        end_time: slotEndTime
                                    },
                                    bookedSlotsData?.[0]
                                )
                            }}
                            trigger={['contextMenu']}
                            destroyPopupOnHide
                            getPopupContainer={() =>
                                document.querySelector('.smart_timeline_table') as HTMLElement
                            }
                        >
                            <Popover
                                trigger={'click'}
                                content={
                                    hasDriverInfos ? (
                                        <Flex>{driverInfos.join(', ')}</Flex>
                                    ) : undefined
                                }
                            >
                                <div>
                                    <ColorMarker
                                        border={showSelectedTripBorder ? border : undefined}
                                        status={Status}
                                        color={CellColor}
                                        width={`${SlotFillPercent}%`}
                                        text={hasText ? totalWeightValue : ''}
                                        isContentStart={IsSlotStart}
                                        // text={hasText ? capacityDiff : ''}
                                    />
                                </div>
                            </Popover>
                        </Dropdown>
                    );
                }
            };
        })
    ];
};
