import { range } from 'lodash';
import { v4 } from 'uuid';
import dayjs from 'dayjs';

import { ItemDataType } from './types';
import { getUniqItemsFromSlots } from './getUniqItemsFromSlots';
import {
    RegBookedLocationSlot,
    RegLocationResourceSlot
} from '../../../../modules/services/backend-api/generated_models';
import { IObjectWithId } from '../../../../utils/store/MetaStore';
import { DATE_FORMAT } from '../../../../utils/helpers/dates';

type GetDataRevertFunction = (options: {
    daysCount: number;
    freeSlots: RegLocationResourceSlot[];
    selectedDate: dayjs.Dayjs;
    bookedSlots: RegBookedLocationSlot[];
    firstColumnFieldName: string;
    selectedTrips: IObjectWithId[];

    // selectedResources: IObjectWithId[];
    // selectedLocations: IObjectWithId[];
    // language: string;

    // selectedDate: dayjs.Dayjs;
    // fetchBookedSlots: () => void;
}) => ItemDataType[];

export const getDataRevert: GetDataRevertFunction = ({
    freeSlots,
    daysCount,
    selectedDate,
    bookedSlots,
    firstColumnFieldName,
    selectedTrips
}) => {
    const result: ItemDataType[] = [];
    const selectedTripIds = selectedTrips.map((t) => t.Id);

    const uniqItems = getUniqItemsFromSlots({
        slots: freeSlots,
        bookedSlots,
        selectedTripIds,
        firstColumnFieldName
    });

    //
    for (const day of range(0, daysCount)) {
        const showDate = selectedDate.local().add(day, 'days');
        const showDateString = showDate.format(DATE_FORMAT);

        for (const hour of range(0, 23)) {
            const time = `${hour}:00`;

            const dataItem: ItemDataType = {
                Id: v4(),
                Hour: hour,
                Time: `${hour}:00`,
                Date: showDateString,
                DateTime: `${showDateString} ${time}`,
                DaysCount: daysCount,
                ...uniqItems.map((i) => ({ [i.Id]: i.Id }))
            };

            result.push(dataItem);
        }
    }

    return result;
};
