import { isEqual } from 'lodash';
import dayjs from 'dayjs';
import { getHoursArray } from './getHoursArray';
import { RegLocationResourceSlot } from '../../../../modules/services/backend-api/generated_models';

const memoized_getFreeHoursByDate = () => {
    const cache: {
        freeSlots?: RegLocationResourceSlot[];
        resourceId?: string;
        result?: Map<string, number[]>;
    } = {
        result: undefined,
        freeSlots: undefined,
        resourceId: undefined
    };

    return (
        freeSlots: RegLocationResourceSlot[],
        resourceId: string,
        firstColumnFieldName: string
    ) => {
        if (isEqual(cache.freeSlots, freeSlots) && cache.resourceId === resourceId) {
            console.log('cached');
            return cache.result as Map<string, number[]>;
        }

        // let totalFreeHours = 0;
        const result = new Map<string, number[]>();

        freeSlots.forEach((slot) => {
            const dateString = String(slot.SlotDate);

            if (slot[firstColumnFieldName]?.Id === resourceId) {
                const existingSlot = result.get(dateString);

                if (existingSlot) {
                    const existingFrom = dayjs(existingSlot[0]).utc();
                    const existingTo = dayjs(existingSlot[1]).utc();

                    const currentFrom = dayjs(slot.SlotFromAt ?? '').utc();
                    const currentTo = dayjs(slot.SlotToAt ?? '').utc();

                    const newFrom = currentFrom.isBefore(existingFrom) ? currentFrom : existingFrom;
                    const newTo = currentTo.isAfter(existingTo) ? currentTo : existingTo;

                    result.set(dateString, [newFrom.toISOString(), newTo.toISOString()]);
                } else {
                    result.set(dateString, [slot.SlotFromAt ?? '', slot.SlotToAt ?? '']);
                }
            }
        });

        // Преобразуем временные диапазоны в массив часов
        for (const [date, [from, to]] of result.entries()) {
            result.set(date, getHoursArray({ from, to }));
        }

        cache.result = result;
        cache.freeSlots = freeSlots;
        cache.resourceId = resourceId;

        return result;
    };
};

export { memoized_getFreeHoursByDate as getGetFreeHoursByDate };
