import dayjs from 'dayjs';

export const getHoursArray = (dateRange: { from: Date | string; to: Date | string }) => {
    const fromDate = dayjs(dateRange.from).utc();
    const toDate = dayjs(dateRange.to).utc();

    const hoursArray = new Set<number>();
    let currentHour = fromDate;

    // Итерируем по часам до тех пор, пока текущий час не превысит конечную дату
    // while (currentHour.isBefore(toDate) || currentHour.isSame(toDate, 'hour')) {
    // строго ДО конечной даты
    while (currentHour.isBefore(toDate)) {
        hoursArray.add(currentHour.hour());
        currentHour = currentHour.add(1, 'hour');
    }

    return [...hoursArray];
};
